<template>
  <div class="basket-content z-20">
    <div class="h-full">
      <div
        class="mx-24 pb-16 border-b-2 border-grey800 flex justify-between flex-1 items-center preamble-text"
      >
        <div class="flex">
          {{
            $lang(
              'shoppingCartResources',
              purchaseType === PurchaseType.Subscription
                ? 'subscriptionBasketHeader'
                : 'basketHeader'
            )
          }}
          <span class="font-normal ml-8">
            {{
              `(${amountOfItems} ${
                amountOfItems == 1
                  ? $lang('shoppingCartResources', 'item')
                  : $lang('shoppingCartResources', 'items')
              })`
            }}
          </span>
        </div>
        <div class="select-none cursor-pointer" @click="$emit('close-overlay')">
          <img
            class="h-24 w-24"
            src="~/assets/icons/close.png"
            height="25"
            width="25"
            alt="close"
          />
        </div>
      </div>
      <div v-if="amountOfItems > 0">
        <div class="px-24">
          <BasketItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            :purchase-type="purchaseType"
            :show-discount="true"
            @loadingPrice="setPriceIsLoading"
          />
        </div>
      </div>
      <div v-else class="py-16 px-24">
        {{ $lang('shoppingCartResources', 'emptyCart') }}
      </div>
    </div>
    <div v-if="amountOfItems > 0" class="sticky bottom-0 bg-white">
      <div class="bg-white px-24">
        <div class="py-16 border-b-2 border-grey800">
          <div class="flex justify-between small-base-text items-center">
            <div>{{ $lang('shoppingCartResources', 'subTotalHeadline') }}</div>
            <div
              class="preamble-text"
              :class="{
                'opacity-50': priceLoading,
              }"
            >
              {{ cartData.subTotal }}
            </div>
          </div>
        </div>
        <div
          v-if="purchaseType === PurchaseType.Subscription"
          class="text-center text-gray-300 text-sm mt-24 block"
        >
          <fa class="mr-2" :icon="['fas', 'exclamation-circle']"></fa>
          {{ $lang('shoppingCartResources', 'subscriptionIntervalNotice') }}
        </div>
        <div class="py-24">
          <Button
            :is-block="true"
            :link-url="checkoutUrl"
            :size="Sizes.lg"
            :theme="Themes.dark"
            :text="$lang('shoppingCartResources', 'proceedToCheckout')"
            @click="$emit('close-overlay')"
          />
          <div class="mt-24 flex justify-center">
            <img
              v-for="logo in paymentLogos"
              :key="'cartPaymentLogo-' + logo"
              class="max-h-18 sm:max-h-24 last:mr-0 mr-24"
              :src="logo"
              height="24"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { Sizes, Themes } from '../constants/button-layouts';
import BasketItem from './BasketItem.vue';
import Button from './globals/Button.vue';
import { PurchaseType } from '~/models/api-types';
import { useCartStore } from '~/store/cart';

const config = useGlobalContentStore().config;
const cartStore = useCartStore();

const { apiGet } = useApiFetch();
const { $lang } = useNuxtApp();

const props = defineProps<{
  purchaseType?: PurchaseType,
}>();

const priceLoading = ref(false);

const items = computed(() => {
  return props.purchaseType === PurchaseType.Subscription
    ? cartStore.subscriptionItems
    : cartStore.items;
});

const cartData = computed(() => {
  return props.purchaseType === PurchaseType.Subscription
    ? cartStore.subscriptionCart
    : cartStore.cart;
});

const amountOfItems = computed(() => {
  return items.value?.length;
});

const paymentLogos = computed(() => {
  const images = config.currentMarket?.cartPaymentLogos || [];
  return images;
});

const checkoutUrl = computed(() => {
  return cartData.value.checkoutUrl || '/';
});

const setPriceIsLoading = (isLoading: boolean) => {
  priceLoading.value = isLoading;
};
</script>
<style>
.basket-content {
  @apply pt-24 flex flex-col;

  max-height: calc(100vh - 80px);
  overflow: auto;
}
.basket-container:not(.basket-container--simple)::after {
  @apply bg-grey800 z-10 border-l border-t border-grey500;

  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -11px;
  right: var(--right-set);
  background: inherit;
  transform: rotate(45deg);
  border-top-width: 1.5px;
  border-left-width: 1.5px;
}

.basket-container {
  max-height: calc(100% - 100px);

  @screen lg {
    max-height: calc(100% - 160px);
  }
}
</style>
